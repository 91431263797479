import React, { useState } from 'react';
import axios from 'axios';

import { jwtDecode } from 'jwt-decode';
import Header from './Header';

import { Button, TextField, Typography, Dialog, DialogTitle, DialogContent, DialogActions, MenuItem , CircularProgress,} from '@mui/material';
import config from './config';
type ApiCallFunction = () => Promise<void>;

interface DecodedToken {
  username: string;
  exp: number;
}




function App() {
  const [selectedAudio, setSelectedAudio] = useState('');
  const [selectedTranscript, setSelectedTranscript] = useState('');
  const [transcriptContent, setTranscriptContent] = useState('');
  const [myprompt, setPrompt] = useState('');
  const [llmResponse, setLlmResponse] = useState('');
  const [audioFiles, setAudioFiles] = useState([]);
  const [transcriptFiles, setTranscriptFiles] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isTranscriptDialogOpen, setIsTranscriptDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const closeLoadingDialog = () => setIsLoading(false);
  const [user, setUser] = useState<string | null>(null);

  // Registrierung eines neuen Benutzers
  const register = async () => {
    const username = prompt('Enter a username:');
    const password = prompt('Enter a password:');

    if (username && password) {
      try {
        await axios.post(config.apiBaseUrlDjango +'/auth/api/register/', {
          username,
          password,
        });
        alert('Registration successful! Please log in.');
      } catch (error) {
        console.error('Registration failed:', error);
        alert('Registration failed. Please try again.');
      }
    }
  };

  // Einloggen eines Benutzers
  const login = async () => {
    const username = prompt('Enter your username:');
    const password = prompt('Enter your password:');

    if (username && password) {
      try {
        const response = await axios.post(config.apiBaseUrlDjango + '/auth/api/token/', {
          username,
          password,
        });
        const token = response.data.access;
        const decoded: DecodedToken = jwtDecode<DecodedToken>(token); // JWT dekodieren
        console.log('Decoded User:', decoded); // Debugging: Überprüfen Sie den dekodierten Benutzer

        setUser(decoded.username); // Benutzername setzen
        localStorage.setItem('token', token); // Token im Local Storage speichern
      } catch (error) {
        console.error('Login failed:', error);
        alert('Login failed. Please check your credentials.');
      }
    }
  };

  // Ausloggen eines Benutzers
  const logout = () => {
    setUser(null); // Benutzer zurücksetzen
    localStorage.removeItem('token'); // Token aus Local Storage entfernen
    alert('Logged out successfully.');
  };



  
   // Typ für apiCall hinzugefügt
   const handleRequest = async (apiCall: ApiCallFunction) => {
    setIsLoading(true);
    try {
      await apiCall();
    } catch (error) {
      console.error('Error during API call:', error);
    } finally {
      setIsLoading(false);
    }
  };
  
  
  // Handle file upload
  const handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]; // Optional chaining, falls files null ist
    if (!file) {
      alert('Keine Audiodatei ausgewählt.');
      return;
    }
  
    const formData = new FormData();
    formData.append('file', file);
  
    try {
      await axios.post(config.apiBaseUrl + '/api/upload-audio', formData);
      alert('File erfolgreich hochgeladen und in das .wav-Format konvertiert');
    } catch (error) {
      console.error('Fehler beim Hochladen der Datei::', error);
    }
  };

  // Fetch audio files
  const fetchAudioFiles = async () => {
    try {
      const response = await axios.get(config.apiBaseUrl + '/api/list-audio-files');
      setAudioFiles(response.data);
      setIsDialogOpen(true);
    } catch (error) {
      console.error('Error fetching audio files:', error);
    }
  };

  // Fetch transcript files
  const fetchTranscriptFiles = async () => {
    try {
      const response = await axios.get(config.apiBaseUrl + '/api/list-transcript-files');
      setTranscriptFiles(response.data);
      setIsTranscriptDialogOpen(true);
    } catch (error) {
      console.error('Error fetching transcript files:', error);
    }
  };

  // Load selected transcript content
  const loadSelectedTranscript = async (filename: string) => {
    try {
      const response = await axios.get(config.apiBaseUrl + `/api/get-transcript/${filename}`);
      setTranscriptContent(response.data.content);
      alert('Transkript erfolgreich geladen.');
    } catch (error) {
      console.error('Error loading transcript:', error);
    }
  };
  
  // Transcribe selected audio file
  const handleTranscriptionGoogle = async () => {
    await handleRequest(async () => {
      const response = await axios.post(config.apiBaseUrl + '/api/transcribe-audio-google', { filename: selectedAudio });
      setTranscriptContent(response.data.content);
      alert('Transkription erfolgreich.');
    });
  };
  const handleTranscriptionLocalSmall = async () => {
    await handleRequest(async () => {
      const response = await axios.post(config.apiBaseUrl + '/api/transcribe-audio-local-small', { filename: selectedAudio });
      setTranscriptContent(response.data.content);
      alert('Transkription erfolgreich.');
    });
  };

  const handleTranscriptionLocalLarge = async () => {
    await handleRequest(async () => {
      const response = await axios.post(config.apiBaseUrl + '/api/transcribe-audio-local-large', { filename: selectedAudio });
      setTranscriptContent(response.data.content);
      alert('Transkription erfolgreich.');
    });
  };

  // Send transcript and prompt to LLM
  const handleLlmRequestChatgpt = async () => {
    await handleRequest(async () => {
      const response = await axios.post(config.apiBaseUrl + '/api/send-to-llm-chatgpt', {
        transcript: transcriptContent,
        prompt: myprompt,
      });
      setLlmResponse(response.data.response);
    });
  };

  const handleLlmRequestLocal = async () => {
    await handleRequest(async () => {
      const response = await axios.post(config.apiBaseUrl + '/api/send-to-llm-local', {
        transcript: transcriptContent,
        prompt: myprompt,
      });
      setLlmResponse(response.data.response);
    });
  };

  // Download combined result
  const handleDownload = () => {
    const blob = new Blob(
      [`Transcript:\n${transcriptContent}\n\nPrompt:\n${myprompt}\n\nLLM Response:\n${llmResponse}`],
      { type: 'text/plain' }
    );
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'result.txt';
    link.click();
    URL.revokeObjectURL(url);
  };

  return (
    <div style={{ padding: '20px' }}>
      <Header user={user} onRegister={register} onLogin={login} onLogout={logout} />
      {user ? (
      <div style={{ padding: '20px' }}>
      <Typography variant="h4">KVITA - Simple: Audio-Transkripte und KI - Analysen</Typography>
      
      {/* Upload Audio Button */}
      <Button variant="contained" component="label" style={{ marginTop: '20px', marginRight: '10px'}}>
      Audiodatei hochladen
        <input type="file" hidden onChange={handleUpload} />
      </Button>

      {/* Select Audio File */}
      <Button variant="contained" onClick={fetchAudioFiles} style={{ marginTop: '20px', marginRight: '10px' }}>
        Audiodatei auswählen
      </Button>

      {/* Transcribe Button Google*/}
      <Button
        variant="contained"
        color="primary"
        onClick={handleTranscriptionGoogle}
        disabled={!selectedAudio}
        style={{ marginTop: '20px', marginRight: '10px' }}
      >
        traskription der gewählten audiodatei mit <br /> GOOGLE Speech to Text API 
      </Button>

     {/* Transcribe Button Local Small */}
      <Button
        variant="contained"
        color="primary"
        onClick={handleTranscriptionLocalSmall}
        disabled={!selectedAudio}
        style={{ marginTop: '20px', marginRight: '10px' }}
      >
        traskription der gewählten audiodatei mit <br /> LOKAL kleines Modell
      </Button>
      
      {/* Transcribe Button Local Large */}
      <Button
        variant="contained"
        color="primary"
        onClick={handleTranscriptionLocalLarge}
        disabled={!selectedAudio}
        style={{ marginTop: '20px', marginRight: '10px' }}
      >
        traskription der gewählten audiodatei mit  <br /> LOKAL großes Modell
      </Button>

     {/* Select Transcript File */}
      <Button variant="contained" onClick={fetchTranscriptFiles} style={{ marginTop: '20px', marginRight: '10px' }}>
        Vorhandenes Transkript laden
      </Button>

      

     {/* Transcript Text Area */}
      <Typography variant="h6" style={{ marginTop: '20px' }}>
        Aktuelle Audiodatei: {selectedAudio || 'Keine Audio ausgewählt'}
      </Typography>
      <Typography variant="h6" style={{ marginTop: '20px' }}>
        Aktuelles Skript: {selectedTranscript || 'Kein Skript ausgewählt'}
      </Typography>
      <Typography variant="h6" style={{ marginTop: '20px' }}>
        Transkript (Editierbar)
      </Typography>
      <TextField
        multiline
        rows={10}
        fullWidth
        variant="outlined"
        value={transcriptContent}
        onChange={(e) => setTranscriptContent(e.target.value)}
        style={{ marginTop: '10px' }}
      />

     {/* Prompt Text Field */}
      <Typography variant="h6" style={{ marginTop: '20px' }}>
        Prompt für LLM 
      </Typography>
      <TextField
        fullWidth
        variant="outlined"
        value={myprompt}
        onChange={(e) => setPrompt(e.target.value)}
        style={{ marginTop: '10px' }}
      />

     {/* Send to LLM Button ChatGPT*/}
      <Button variant="contained" color="secondary" onClick={handleLlmRequestChatgpt} style={{ marginTop: '20px', marginRight: '10px' }}>
        An LLM senden <br /> ChatGPT
      </Button>

    {/* Send to LLM Button Local*/}
      <Button variant="contained" color="secondary" onClick={handleLlmRequestLocal} style={{ marginTop: '20px', marginRight: '10px' }}>
        An LLM senden <br /> LOKAL
      </Button>

     {/* LLM Response */}
      <Typography variant="h6" style={{ marginTop: '20px' }}>
        LLM Antwort
      </Typography>
      <TextField
        multiline
        rows={5}
        fullWidth
        variant="outlined"
        value={llmResponse}
        style={{ marginTop: '10px' }}
        
      />

     {/* Download Result */}
      <Button variant="contained" color="success" onClick={handleDownload} style={{ marginTop: '20px' }}>
        Ergebnis herunterladen
      </Button>

      <Dialog open={isLoading} onClose={closeLoadingDialog}>
        <DialogContent>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CircularProgress style={{ marginRight: '10px' }} />
            <Typography>Bitte warten...</Typography>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeLoadingDialog}>Ausblenden <br />  (bricht nicht ab!)</Button>
        </DialogActions>
      </Dialog>

     {/* Dialog for Audio File Selection */}
      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <DialogTitle>Select Audio File</DialogTitle>
        <DialogContent>
          {audioFiles.map((file) => (
            <MenuItem
              key={file}
              onClick={() => {
                setSelectedAudio(file);
                setIsDialogOpen(false);
              }}
            >
              {file}
            </MenuItem>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>

     {/* Dialog for Transcript File Selection */}
      <Dialog open={isTranscriptDialogOpen} onClose={() => setIsTranscriptDialogOpen(false)}>
        <DialogTitle>Select Transcript File</DialogTitle>
        <DialogContent>
          {transcriptFiles.map((file) => (
            <MenuItem
              key={file}
              onClick={() => {
                setSelectedTranscript(file);
                setIsTranscriptDialogOpen(false);
                loadSelectedTranscript(file);
              }}
            >
              {file}
            </MenuItem>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsTranscriptDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      </div>) : (
        <p>Bitte loggen Sie sich ein, um KVITA nutzen zu können.</p>
        
      )}
    </div>
  );
}

export default App;