import React from 'react';

interface HeaderProps {
  user: string | null;
  onRegister: () => void;
  onLogin: () => void;
  onLogout: () => void;
}

const Header: React.FC<HeaderProps> = ({ user, onRegister, onLogin, onLogout }) => {
  return (
    <div style={{ background: '#f5f5f5', padding: '10px', display: 'flex', justifyContent: 'space-between' }}>
      <span>{user ? `Welcome, ${user}` : 'Please log in or register'}</span>
      <div>
        {!user ? (
          <>
            <button onClick={onRegister} style={{ marginRight: '10px' }}>
              Register
            </button>
            <button onClick={onLogin}>Login</button>
          </>
        ) : (
          <button onClick={onLogout}>Logout</button>
        )}
      </div>
    </div>
  );
};

export default Header;
